'use client'

import { useEffect, useState } from 'react'
import { Icon, Spacer, Image, Cell, Text } from '@vinted/web-ui'
import { InfoCircle24 } from '@vinted/monochrome-icons'

import useTranslate from 'hooks/useTranslate'
import useFetch from 'hooks/useFetch'
import useTracking from 'hooks/useTracking'
import useAsset from 'hooks/useAsset'
import useSession from 'hooks/useSession'

import { getSellerBadges } from 'data/api'
import { transformSellerBadgesResponse } from 'data/api/transformers/response'
import { Screen } from 'constants/tracking/screens'
import { clickEvent } from 'libs/common/event-tracker/events'
import { ClickableElement } from 'constants/tracking/clickable-elements'
import { UserBadgeDto } from 'types/dtos'

import BadgeInfoModal from '../BadgeInfoModal/BadgeInfoModal'

const BadgeBanner = () => {
  const translate = useTranslate('profile.badges.active_lister')
  const { track } = useTracking()
  const asset = useAsset('/assets/user-badges')

  const [isModalOpen, setIsModalOpen] = useState(false)

  const { user } = useSession()

  const { transformedData: badges, fetch: fetchBadges } = useFetch(
    getSellerBadges,
    transformSellerBadgesResponse,
  )

  const showBadgeBanner = user && !user.business

  useEffect(() => {
    if (!showBadgeBanner) return

    fetchBadges()
  }, [fetchBadges, showBadgeBanner])

  if (!badges?.[0]) return null

  const { progress } = badges[0]

  const handleOnClick = (badgeProgress: UserBadgeDto['progress']) => () => {
    track(
      clickEvent({
        screen: Screen.CurrentUserProfile,
        target: ClickableElement.ListerBadge,
        targetDetails: JSON.stringify({ current: badgeProgress.current, goal: badgeProgress.goal }),
      }),
    )
    setIsModalOpen(true)
  }

  const getBodyTranslation = (items_count: number) => {
    if (progress.current === 0) return translate('body', { items_count: progress.goal })
    if (progress.current < progress.goal)
      return translate('body_progress', { items_count }, { count: items_count })

    return translate('body_success')
  }

  const getTitleTranslation = () => {
    if (progress.current === 0) return translate('title')
    if (progress.current < progress.goal) return translate('title_progress')

    return translate('title_success')
  }

  const getProgressIcon = () => {
    if (progress.current === 0) return asset('lister-badge-empty.svg')
    if (progress.current === progress.goal) return asset('lister-badge-final.svg')

    return asset(`lister-badge-${progress.goal}-${progress.current}.svg`)
  }

  const itemsRemaining = progress.goal - progress.current

  return (
    <>
      <Spacer size={Spacer.Size.Medium} />
      <Cell type={Cell.Type.Navigating} onClick={handleOnClick(progress)} testId="badge-banner">
        <div className="u-flexbox u-justify-content-between">
          <div className="u-flexbox">
            <div>
              <Image
                src={getProgressIcon()}
                alt=""
                size={Image.Size.XLarge}
                scaling={Image.Scaling.Contain}
              />
            </div>
            <Spacer size={Spacer.Size.Regular} orientation={Spacer.Orientation.Vertical} />
            <div className="u-align-self-center">
              <Text text={getTitleTranslation()} type={Text.Type.Title} />
              <br />
              <Text text={getBodyTranslation(itemsRemaining)} />
            </div>
          </div>
          <Spacer size={Spacer.Size.Regular} orientation={Spacer.Orientation.Vertical} />
          <div>
            <Icon name={InfoCircle24} color={Icon.Color.GreyscaleLevel3} />
          </div>
        </div>
      </Cell>
      <BadgeInfoModal
        show={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        itemsCount={progress.goal}
      />
    </>
  )
}

export default BadgeBanner
